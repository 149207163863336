import React from 'react';
import styled from 'styled-components';
import ButtonDefault from './ButtonDefault';
import { Subtitle5 } from './Typography';

const LowerTextWrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  border-radius: 20px;
  margin-top: 5rem;
  /* height: 30vh; */
  padding: 92px 245px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1200px) {
    height: auto;
    padding: 2rem 1rem;
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 90px;
  background-color: #fff;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Label = styled(Subtitle5)`
  margin-bottom: 1rem;
`;

interface ComponentProps {
  left: {
    label: string;
    button: {
      label: string;
      url: string;
    };
  };

  right: {
    label: string;
    button: {
      label: string;
      url: string;
    };
  };
  bannerBg: string;
}
const TwoSidesBottomMessage: React.FunctionComponent<ComponentProps> = ({
  left,
  bannerBg,
  right,
}) => (
  <LowerTextWrapper resource={bannerBg}>
    <Left>
      <Label> {left.label}</Label>
      <div>
        <ButtonDefault to={left.button.url}>{left.button.label}</ButtonDefault>
      </div>
    </Left>
    <Divider />
    <Right>
      <Label>{right.label}</Label>
      <div>
        <ButtonDefault to={right.button.url}>
          {right.button.label}
        </ButtonDefault>
      </div>
    </Right>
  </LowerTextWrapper>
);

export default TwoSidesBottomMessage;
