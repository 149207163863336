import React from 'react';
import styled from 'styled-components';
import { Body4, Subtitle5 } from './Typography';
import ButtonDefault from './ButtonDefault';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 35%;
  background-color: #fff;
  box-shadow: 0px 8px 26px 0px #e7edf3;
  padding: 45px 45px 25px 45px;
  border-radius: 10px;
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }
`;

const WooImgWrapper = styled.div`
  display: flex;
  height: 50px;
`;

const ImgWrapper = styled.div`
  display: flex;
`;
const WooImg = styled.img`
  width: 80px;
  height: 50px;
`;

const DescriptionWrapper = styled.div`
  min-height: 190px;
  margin-top: 1rem;
`;
const Img = styled.img``;

const Title = styled(Subtitle5)`
  margin-left: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled(Body4)``;

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ComingSoonText = styled(Subtitle5)`
  color: #ef6355;
  margin-left: 1rem;
  font-size: 14px;
  margin-top: 2px;
`;

interface PluginCardProps {
  title?: string;
  description: string;
  image: string;
  button: {
    label: string;
    url: string;
  };
  comingSoon: boolean;
}

const ToolkitPluginCard: React.FunctionComponent<PluginCardProps> = ({
  title,
  description,
  image,
  button,
  comingSoon,
}) => (
  <Wrapper>
    <Header>
      {title.toLowerCase() === `woocommerce` ? (
        <WooImgWrapper>
          <WooImg src={image} alt="card logo" />
        </WooImgWrapper>
      ) : (
        <ImgWrapper>
          <Img src={image} alt="card logo" />
        </ImgWrapper>
      )}
      <Title>{title}</Title>
      {comingSoon && <ComingSoonText>COMING SOON!</ComingSoonText>}
    </Header>
    <DescriptionWrapper>
      <Description>{description}</Description>
    </DescriptionWrapper>
    <Button>
      {!comingSoon && (
        <ButtonDefault to={button.url}>{button.label}</ButtonDefault>
      )}
    </Button>
  </Wrapper>
);

export default ToolkitPluginCard;
