import React from 'react';
import Main from '../containers/Layout';
import plugInsPageContent from '../../content/pages/plug-ins.yml';
import PlugInsContainer from '@/containers/toolkit/PlugInsContainer';

export interface PlugInsPageContent {
  pageTitle: string;
  pageDescription: string;
  plugIns: {
    image: string;
    title: string;
    description: string;
    link: string;
    button: { label: string; url: string };
    comingSoon: boolean;
  }[];
  bottomText: {
    label: string;
    description1: string;
    description2: string;
    description3: string;
  };
  twoSideBanner: {
    left: {
      label: string;
      button: {
        label: string;
        url: string;
      };
    };

    right: {
      label: string;
      button: {
        label: string;
        url: string;
      };
    };
  };
}
const Pricing: React.FunctionComponent = () => {
  const { pageTitle, pageDescription, plugIns, bottomText, twoSideBanner } =
    plugInsPageContent as unknown as PlugInsPageContent;
  return (
    <Main>
      <PlugInsContainer
        content={{
          pageTitle,
          pageDescription,
          plugIns,
          bottomText,
          twoSideBanner,
        }}
      />
    </Main>
  );
};

export default Pricing;
