import React from 'react';
import styled from 'styled-components';
import { Body2, H2, H3, P } from '@/components/Typography';
import Section from '@/components/Section';
import { PlugInsPageContent } from '@/pages/plug-ins';
import ToolkitPluginCard from '@/components/ToolkitPlugInCard';
import bannerBg from '../../assets/img/bannerFenaTerminal.png';
import TwoSidesBottomMessage from '@/components/TwoSidesBottomMessage';

const Wrapper = styled.section`
  background: #f4f7f9;
`;
const FirstViewport = styled(Section)`
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Description = styled(P)`
  margin-top: 2rem;
  color: #495b6c;
`;

const PlugIns = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5rem;
  gap: 60px;
`;

const Bottom = styled(Section)``;

const BottomWrapper = styled.div`
  background-color: #fff;
`;

const BottomTitle = styled(H2)`
  color: #2cd19e;
  text-align: center;
`;

const BottomDescription = styled(Body2)`
  text-align: center;
  margin-top: 1rem;
`;

interface PlugInsPageProps {
  content: PlugInsPageContent;
}
const PlugInsContainer: React.FunctionComponent<PlugInsPageProps> = ({
  content: { pageTitle, pageDescription, plugIns, twoSideBanner, bottomText },
}) => (
  <Wrapper>
    <FirstViewport>
      <Text>
        <H3>{pageTitle}</H3>
        <Description>{pageDescription}</Description>
      </Text>

      <PlugIns>
        {plugIns.map((item) => (
          <ToolkitPluginCard
            key={item.title}
            title={item.title}
            description={item.description}
            image={item.image}
            comingSoon={item.comingSoon}
            button={item.button}
          />
        ))}
      </PlugIns>
    </FirstViewport>
    <BottomWrapper>
      <Bottom>
        <BottomTitle> {bottomText.label}</BottomTitle>
        <BottomDescription>
          {bottomText.description1}&nbsp;
          <span className="accent-text">{bottomText.description2}</span>&nbsp;
          {bottomText.description3}
        </BottomDescription>

        <TwoSidesBottomMessage
          left={twoSideBanner.left}
          right={twoSideBanner.right}
          bannerBg={bannerBg}
        />
      </Bottom>
    </BottomWrapper>
  </Wrapper>
);

export default PlugInsContainer;
